body {
    margin: 0;
    font-family: 'IranSans';
    color: #495057 !important;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.py-3{
    margin: 3px 0 !important;
}
.mt-20{
    margin-top: 0;
}
.mt-15{
    margin-top: 15px !important;
}
.mt-10{
    margin-top: 10px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.mb-0{
    margin-bottom: 0 !important;
}
.my-5{
    margin: 5px 0 !important;
}
.my-10{
    margin: 10px 0 !important;
}
.my-20{
    margin: 20px 0 !important;
}
.m-chip{
    margin: 3px 3px 3px 0 !important;
}
.mx-5{
    margin-right:5px;
    margin-left: 5px;
}
.mb-15{
    margin-bottom: 15px !important;
}
.mr-10{
    margin-right: 10px !important;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.MuiPaper-elevation2{
    box-shadow: none !important;
}
a{
    text-decoration: none;
    color: inherit;
}

.text-center{
    text-align: center;
}
.MuiPaper-elevation2{
    box-shadow: none !important;
}

.withCursor{
    cursor: pointer;
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;

}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.img-responsive{
    display: inline-block;
    max-width: 100%;
}
.MuiDivider-light{
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'IranSans';
    font-size: 14px;
    padding: 15px;
    position: relative;
    color:#333 !important;
}
.RichEditor-root.error{
    border: 1px solid #f44336;
}
.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;

}
.RichEditor-editor img{
    max-width: 100%;
}
.editor-view{
    font-family: 'IranSans';
    font-size: 14px;
    margin:10px 0
}
.editor-view a{
    text-decoration: none;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
    color: #4A4A4A
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 400px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'IranSans';
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}
.RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
    display: inline;
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 400;
}
.RichEditor-styleButton {
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #328be0
}
.tox-notifications-container{
    display: none;
}
.rdw-link-decorator-wrapper{
    color:blue
}
.rdw-image-modal{
    right:5px
}
.rdw-dropdown-carettoopen{
    left:10px;
    right: unset !important;
}

.MuiSnackbarContent-message .MuiSvgIcon-root {
    margin-left: 10px;
}

span.MuiButtonBase-root.MuiIconButton-root{
    margin-left: 0 !important;
}

/*
#ProviderUserDetails tr.MuiTableRow-root[index="0"] span[title="ویرایش سریع"] .MuiButtonBase-root
,#ProviderUserDetails tr.MuiTableRow-root[index="0"] span[title="پاک کن"] .MuiButtonBase-root{
  display: none;
}
#ProviderUserDetails tr.MuiTableRow-root[index="0"]{
  background-color: rgba(70, 200, 30, 0.1);
}*/
.profile{
    width: 40px;
    border-radius: 50%;
    border: 1px solid #ffdd14;
}
.heading{
    font-weight: 700 !important;
    display: block;

}
.subheading{
    font-weight: 700 !important;
    margin-top: 0 !important;
    font-size: 15px !important;
    white-space: nowrap !important;
}

.MuiListItemIcon-root{
    min-width: 50px !important;
}

.MuiPaper-elevation4{
    box-shadow: none !important;
}
.MuiBreadcrumbs-separator{
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.TitleContainer h2{
    font-size: 20px;
    text-align: right;
    padding: 12px 10px 10px;
}
.TitleContainer h2:after{
    width: unset;
    transform: unset;
    margin-right: unset;
}
.SubTitleContainer{
    background: #22b2b8;
    margin-top: -25px !important;
    border-radius: 3px;
    margin-bottom: 15px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-bottom: 5px !important;
}
.SubTitleContainer svg{
    color: #fff !important;
}
.SubTitleContainer h3{
    color: #fff !important;
}
.MuiLinearProgress-root{
    height: 5px !important;
    margin-bottom: 5px;
    border-radius: 5px;
}
.MuiStepLabel-root,.MuiStepLabel-root.Mui-disabled{
    cursor: pointer !important;
}
.MuiTypography-body1{
    font-size: 15px;
}
.MuiStepper-root{
    padding-top: 10px !important;
}
.MuiListItemText-root span.MuiTypography-body1{
    font-size: 14px !important;
    color:#495057;
}

.uploadProgress{
    display: inline-block;
}
.uploadProgress span{
    margin-right: 10px;
    transition: 1s all ease-in-out;
}
.uploadProgress span.success{
    color: #4caf50;
}
.uploadProgress .MuiCircularProgress-root{
    position: relative;
    top: 13px;
    right: 6px;
}
.attributes-holder{
    margin-top:20px;
}
.attributes-holder .MuiExpansionPanel-root{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid #22b2b8;
}
.autoCompleteHolder .MuiAutocomplete-endAdornment {
    top: calc(50% - 23px) !important;
}
.textCounter .MuiLinearProgress-barColorPrimary {
    background-color: #4caf50 ;
}
.textCounterError .MuiLinearProgress-barColorPrimary {
    background-color: #f44336;
}
.textCounterError.MuiLinearProgress-root,.textCounter.MuiLinearProgress-root{
    border-radius: 20px !important;
    height: 3px !important;
}
.iconButtonEdit{
    position: absolute !important;
    left: 12px !important;
    top: 27px !important;
}

.MuiCollapse-wrapper .MuiListItemIcon-root{
    min-width: 35px !important;
}

.productFeature {
    background-color: rgb(156, 39, 176);
    border-radius: 4px;
    padding: 5px 15px;
    display: inline-block;
    color: #fff;
}
.VerifyEmailDrawer .MuiPaper-root{
    background-color: rgba(230, 87, 87, 0.90) !important;
    color: #ffffff !important;
    overflow: hidden;
    z-index: 1201;

}

.VerifyEmailDrawer .VerifyEmailButton {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #ffffff !important;
}
@media (max-width: 768px) {
    .VerifyEmailDrawer .VerifyEmailButton {
        position: relative;
        top: -20px;
        color: #ffffff !important;
    }
}

.
{
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #1f1f1f7a;
}
.inActiveCard{
    border:2px solid transparent;
}
.activeCard{
    border:2px solid #ffb63e;
}
.doItLaterButton{
    position: absolute !important;
    left: 10px;
}
.noneListStyle{
    list-style-type: none;
}
.FieldIcon{
    position: absolute !important;
    top: 6px !important;
    right: 5px !important;
}
.FieldIconLeft{
    position: absolute !important;
    top: 6px !important;
    left: 5px !important;
}
.MuiTypography-root.MuiTypography-h6{
    font-size:14px !important;
    font-weight: 700;
}
.MuiFormLabel-root{
    font-size:.9rem !important;
}
.avatarBigCenter{
    width: 200px !important;
    height: 200px !important;
    margin-right: 50%;
    transform: translateX(50%);
    margin-top:20px;
    margin-bottom:20px;
}
.categoryHolderPro{
    height: 300px;
    overflow-y: auto;
}
.categoryHolderPro.error{
    border : 1px solid #f44336;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.box-bordered{
    box-shadow: none !important;
    border:1px solid #333333;
}
.categoryParent span{
    font-weight: 700 !important;
    color : #328be0;
}

.GoogleSimulator{
    direction: ltr;
}
.GoogleSimulator .url{
    font-size: 12px;
    font-weight: 700;
}
.GoogleSimulator .title{
    font-size: 20px;
    color: #1a0dab
}
.wordCounter-Editor{
    position: absolute;
    bottom: 0;
    left: 4px;
    background: #fff;
    padding: 3px;
    z-index:1;
}
.hoverLinkMenu{
    cursor: pointer;
    color: #3b64ef;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;
}

.sketch-picker > div,.sketch-picker  div.flexbox-fix{
    display: none !important;
}
.sketch-picker  div.flexbox-fix:last-child{
    display: flex !important;
}
.sketch-picker{
    padding: 0 10px 0 !important;
}

.ColorPicker_holder > div {
    display: inline;
}
.ColorPicker_holder{
    margin: 5px  15px;
    display: inline-block;
    position: relative;
}

.thumbnailCloser{
    position: absolute !important;
    top:0 !important;
    right: 0 !important;
}
.field-sticky{
    position: sticky;
    top: 60px;
    background-color: #ffffff;
    z-index: 900;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 4px 0 !important;
}
.MuiExpansionPanelSummary-content {
    margin: 2px 0 !important;

}
.link {
    color: #328be0
}
.jsonEditor div{
    margin:5px !important;
    font-family: "IranSans";
    color: #5377c0 !important;
    min-width: 150px;
}
.jsonEditor input{
    font-family: "IranSans";
    min-width: 300px;
    padding: 7px !important;
}
.jsonEditor span[title="remove item"]{
    display: none;
}
.gridLink{
    cursor: pointer;
}
.gridLink:hover button{
    transform: scale(1.1);
    background: rgba(0, 0, 0, 0.8);

}
.MuiTypography-h5 {
    font-size: 1.3rem !important;
}
.editor-control{
    background: #ffffff;
}
.badgeForMenu .MuiBadge-anchorOriginTopRightRectangle{
    top:11px;
    left:-20px;
}
.announcementHolder{
    position: relative;
    margin-top:5px;
    margin-bottom: 10px;
    text-align: right;
}
.announcementHolder .MuiCardContent-root:last-child {
    padding-bottom:10px;
}
.announcementHolder .time-shower{
    position: absolute;
    left: 5px;
    top: 105px;
    background: #fff;
    border-radius: 20px;
    font-size: 13px;
    padding: 5px 10px;
}
.announcementHolder .new-item{
    position: absolute;
    right: 5px;
    top: 105px;
    background: #76d363;
    border-radius: 20px;
    font-size: 13px;
    padding: 5px 10px;
    color:#fff !important;
}
.onClickedField{
    position: absolute;
}
.largeFieldHolder{
    position: absolute;
    top:0;
    right:0;
    background: #ffffff;
    z-index: 999;
}
.largeField{
    width:300px;
}
.app-bar {
    box-shadow: 0 0 8px 0px #d3d7e9 !important;
}
.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
    margin-right: 8px;
}
.MuiButton-contained,.MuiButton-root{
    box-shadow: none !important;
}

.insta-field input{
    direction: ltr;
}
.notifications-holder {
    padding:10px;
    height:88%;
    overflow:auto
}
.notifications-holder .MuiAlert-message {
    width: 100%;
}
.MuiTableRow-root[level="1"]{
    background-color: #fbfdca;
}
.MuiTableRow-root[level="2"]{
    background-color: #fdf59f;
}
.MuiTableRow-root[level="3"]{
    background-color: #fdf764;
}
.MuiTableRow-root[level="1"] span.MuiButtonBase-root.MuiIconButton-root {
    margin-right: 20px;
    margin-left: 0 !important;
}
.MuiTableRow-root[level="2"] span.MuiButtonBase-root.MuiIconButton-root {
    margin-right: 40px;
    margin-left: 0 !important;
}
.MuiTableRow-root[level="3"] span.MuiButtonBase-root.MuiIconButton-root {
    margin-right: 60px;
    margin-left: 0 !important;
}
.MuiTableCell-root{
    padding:9px !important;
}
@media (max-width:768px){
    .MuiTableCell-root{
        padding:5px !important;
    }
}
.scrollbar-container{
    overflow-x: hidden !important;
}
.liveUserNumber{
    margin: 30px;
    font-size: 60px;
    font-weight: 700;
    display: block;
    text-align: center;
    cursor: pointer;
}
.liveUserNumber span{
    font-size: 20px;
    font-weight: 500;
}
.imageViewer{
    display: inline-block;
    max-width: 100%;
}
.float-left{
    float: left;
}
.sticky-grid{
    top:0;
    position: sticky;
}
.thin-table .MuiTableRow-root{
    height:0 !important;
}
.thin-table .MuiToolbar-root{
    display: none;
}
.navbarNav .MuiIcon-root{
    color: #808183;
    font-size: 1.3rem;
    margin-left: 4px !important;
}
.disabled-text{
    color: #7d7d77;
}
.error-text{
    font-size: 0.75rem;
    margin: 5px 20px;
}
.modal-close-btn{
    position: absolute !important;
    left: 10px;
    top: 10px;
}
.DatePicker {
    position: relative;
    display: inline-block;
    z-index: 9999 !important;
}
.DatePicker__calendarArrow {
    z-index: 99999 !important;

}
.overflowVisible {
    overflow: visible;
}
.floated-delete-button{
    float: left;
    margin:10px
}
.pending{
    font-size: 12px;
    color:#328be0;
}
.pending-card{
    background: #f3f3f3 !important;
}
.bottom-editor-controls{
    border-top: 1px solid #dddddd;
    position: relative;
    top: 20px;
}

.icon-btn-active{
    background-color: rgb(91 162 230) !important;
}
.active-point span{
    color: #00d35b;
    transform: scale(1.05);
}
.decreasing-point span{
    color: #ff1400;
    transform: scale(0.95);
}

.menu-tabs .MuiTab-root {
    min-width: 60px;
}
.table .MuiTableCell-stickyHeader{
    background-color: #ffffff;
}
.item-hover:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.2);
    background-color: rgba(10, 143, 220, 0.1);
}
.item-hover {
     transform: scale(1);
     transition: all .2s ease;
 }
.pointer{
    cursor: pointer;
}

.interest-holder{
    position: relative;
}
.interest-chooser{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}
.interest-chooser h2{
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 17px !important

}
.interest-media{
    height:150px
}
.interest-media:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0 ,.5);
    transition: .3s ease-in;
}
.interest-holder.active .interest-media:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(41,120,195,0.4);
    transition: .3s ease-in;
}
.no-link{
    filter:blur(1px);
}

.service-holder .MuiTab-textColorSecondary.Mui-selected{
    background: #fff;
    border-radius: 8px;
}
.service-holder .MuiTabs-indicator{
    background-color: transparent;
}
.service-holder .service-holder-tabs {
    background: #e0e5ef;
}
.service-holder .MuiTab-textColorSecondary{
    -webkit-border-radius: 0 !important;
    -moz-border-radius:0 !important;
    border-radius: 0 !important;
}
.resume-holder .custom_tabs,.custom_tabs .MuiTypography-root{
    cursor: pointer;
}
.resume-holder .custom_tabs_icon_button{
    margin-bottom: 5px !important;
    background: #cccccc !important;
    color:#fff !important;
}
.resume-holder .custom_tabs_icon_button:after{
    content: '';
    border-bottom: 1px solid #bbb;
    width: 120%;
    position: absolute;
    z-index: 9999;
    right: 50px;
}
.resume-holder .custom_tabs_icon_button:before{
    content: '';
    border-bottom: 1px solid #bbb;
    width: 120%;
    position: absolute;
    z-index: 9999;
    left: 50px;
}
.resume-holder .MuiButtonBase-root:first-child .custom_tabs_icon_button:before{
    border:none;
}
.resume-holder .MuiButtonBase-root:last-child .custom_tabs_icon_button:after{
    border:none;
}
.resume-holder .MuiTab-textColorSecondary.Mui-selected .custom_tabs_icon_button{
    background: linear-gradient(
            136deg
            , rgb(91 162 230) 0%, #337ab7 100%) !important;
}
.resume-holder .MuiTab-textColorSecondary.Mui-selected h3{
    color: #328be0;
}
.resume-holder .MuiTab-textColorSecondary.Mui-selected .MuiSvgIcon-root{
    color: #fff;
}

.resume-holder .MuiTabs-indicator{
    background: transparent;
}
.menu-tabs{
    background: #f4f7fe;

}
.expert_info{
    position: absolute;
    bottom: 0;
    background: #fff;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 4px #e8e8e8;
    left: 0;
}
.notif-badge{
    top: -3px;
    left: -2px;
    font-size: 10px;
}
.MuiTableCell-head{
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    background-color: #f6f6f6 !important;
    font-weight: 700 !important;
}
.MuiTable-root {
    border: 1px solid rgba(224, 224, 224, 1) !important;
}
.max-width{
    max-width: 100%;
}
.header-back{
    margin-bottom: 20px !important;
    background: #e4ebfb;
    padding-left:20px !important;
    padding-right:20px !important;
    border-radius: 4px;
}
.absolute-left{
    position: absolute !important;
    top: 10px;
    left: 10px;
}
.avatar-in-report{
    width: 60px;
    height: 60px;
    display:inline-block !important;
    position: relative !important;
    top: 16px;
    margin-left: 10px;
    margin-right: 5px;
}
.MuiOutlinedInput-input {
    padding: 13.5px 12px !important;
    font-size: 14px !important;
}
.MuiInputLabel-outlined {
    transform: translate(-12px, 15px) scale(1) !important; ;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(-14px, -6px) scale(0.75) !important;
}
.MuiFormControl-marginNormal {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 5.5px 4px !important;
}
.make_center_in_card{
    position: absolute;
    top: 50%;
    width: 100%;
    right: 0;
    transform: translateY(-50%);
}
.card_resume_action{
    position: absolute;
    left:0;
    bottom: 0;
    justify-content: flex-end;
}
.auto-li{
    font-size:12px;
    line-height: 30px;
    height: 35px !important;

}

@media(max-width:768px){
    .MuiTablePagination-input{
        display: none !important;
    }

}
.price_cart{
    text-align: center !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    margin-top: 30px !important;
    color:#613de9
}
.checkout-card-no-border{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.payOrderSumery{
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    background: #f4f7fe;
}
.fab{
    position: fixed  !important;
    bottom: 35px;
    left: 10px;
    z-index: 999999;
}
.product-title{
    background: #f4f7f3;
    font-weight: 700 !important;
    font-size: 25px !important;
    text-align: center;
    margin: 19px 0;
    padding: 20px 0;
}
.product-desc{
    font-size: 15px !important;
    text-align: center;
    margin:40px 0 !important;
}
.old-price {
    color: #4c4c4c !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-decoration: none;
    display: inline-block;
    position: relative;
}
.old-price:before {
     content: " ";
     display: block;
     width: 100%;
     border-top: 2px solid rgba(255, 0, 0, 0.8);
     height: 12px;
     position: absolute;
     bottom: 0;
     left: 0;
     transform: rotate(
             -7deg
     );
 }
.muicc-hsvgradient-cursor{
    right:unset !important;
}
.cart_items_holder{
    margin-bottom: 5px !important;
    margin-top: 0 !important;
    padding: 10px 5px;
    background: #f4f7fe;
}
.disabled-card{
    background:#e4ebfd !important;
    box-shadow: none !important;

}
.fixed-alert{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 17rem;
    padding: 5px 20px !important;
}
@media (max-width:1280px){
    .fixed-alert{
        right: 0;
    }
}
.guidline-holder{
    border:1px solid #ddd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.guidline-single{
    position: relative;
    text-align: center;
    border: 1px solid #ddd;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.disabled-appCard{
    color: rgb(51, 51, 51) !important;
    background: rgb(251 251 251) !important;
}
.disabled-appCard img{
    filter: grayscale(100%);
}
.disabled-appCard h2,.disabled-appCard p{
    color:rgba(0, 0, 0, 0.5) !important
}
.simple-error-icon{
    color:#f44336;
    font-size: 1rem !important;
}
.simple-success-icon{
    color: #00d35b;
    font-size: 1rem !important;
}
.simple-error-text{
    color:#333;
    font-size: 11px !important;
    display: block;
    text-align: center;
}
.guidline-arrow{
    position: absolute;
    left:5px;
    top:50%;
    transform: translateY(-50%);
}
.simple-error-text a{
    border: 1px solid #ddd;
    padding: 3px;
    border-radius: 5px;
    white-space: nowrap;
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
}
.MuiFormControlLabel-label{
    white-space: nowrap;
}
.user_info_avatar{
    width:60px !important;
    height:60px !important;
}
.article-card{
    background: #ffffff !important;
    box-shadow:none !important;
    border:1px solid #dddddd;
}
.article-card p {
    font-size: 13px !important;
    color: #777 !important;
}
.article-card h3{
    font-size: 15px !important;

}
.article-avatar{
    width:100px !important;
    height:100px !important;
}
.user_info_avatar.point{
    background: rgba(0,181,156,0.4) !important;
    color: #00b59c;
}
.user_info_avatar.wallet{
    background: #ffb63e5c !important;
    color: #ff9f00;
}
.user_info_avatar.opp{
    background: rgba(65,167,255,.25) !important;
    color: #289cff;
}
.user_info_avatar svg{
    font-size: 30px !important;
}
.text-justify{
    text-align: justify !important;
    text-justify: inter-word !important;
}
.customer-services-holder{
    position: relative;
}
.customer-services-holder.normal{
    background: #50bada url(https://irankhebre.com/assets/images/landing-bg.png) no-repeat !important;
    background-size: cover;
}

.customer-services-holder.smart{
    background: #00b59c url(https://irankhebre.com/assets/images/landing-bg.png) no-repeat !important;
    background-size: cover;
    filter: grayscale(0.8);
    position: relative;
}
.soon{
    position: absolute;
    top: -10px;
    left: -28px;
    background-color: #328be0;
    border-radius: 22px;
    padding: 10px 15px;
    color: #fff;
    text-align: center;
}
.customer-services-holder h3{
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 15px;
    cursor: pointer;

}
.customer-services-holder p{
    color: #ffffff;
    cursor: pointer;
}
.customer-services-holder img{
    max-width: 100%;
    max-height: 250px;
    cursor: pointer;

}